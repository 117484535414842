import { gql } from '@apollo/client'

export const GET_TASK_GUIDE_BY_ID = gql`
  query ContentfulGetTaskById($preview: Boolean!, $taskGuideId: String!) {
    taskGuide(preview: $preview, id: $taskGuideId) {
      taskName
      sys {
        publishedAt
      }
      domainDescription(preview: $preview) {
        domainName
        domainDescription
      }
      taskDescription(preview: $preview) {
        taskDescriptionText
      }
      taskScoresheet(preview: $preview) {
        taskScoresheetPdfCollection(preview: $preview) {
          items {
            url
            title
          }
        }
      }
      taskAdministrationManual(preview: $preview) {
        taskAdministrationManualPdfCollection(preview: $preview) {
          items {
            url
            title
          }
        }
      }
      taskDataDictionary(preview: $preview) {
        taskDataDictionary
      }
    }
  }
`
